import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SeparatorWave } from "../components/course/separator-wave/SeparatorWave"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <SeparatorWave/>
    <div className="container-big center">
      <h1>QUESTA PAGINA NON ESISTE</h1>
      <p>Torna alla <a href="../">homepage</a> del sito</p>
    </div>
  </Layout>
)

export default NotFoundPage
